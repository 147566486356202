.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img {
  width: 40vw;
  margin-left: 10%;
  border-radius: 30px;
}

.home{
  display: flex;
  flex-direction: row-reverse;
  width: 80%;
  align-items: center;
  margin:  2.5% auto;
}

.legend{
  width: 70%;
  border: 2px solid black;
  background-color: #dee1ec;
  padding: 2%;
}

section{
  display: flex;
  width: 80%;
  margin: auto;
  border-top: solid black 2px;
  border-bottom: solid black 2px;
}

.col{
  margin-top: 4%;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

footer{
  margin-top: 3%;
}

.container{
  background-color: #dee1ec;
  border: 1px solid black;
  margin: 5% 10%;
  padding: 2%;
}

nav{
  display: flex;
  justify-content: space-around;
}

nav a {
  text-decoration: none;
  background-color: #6079f1;
  padding: 0.4%;
  border-radius: 70%;
}

#contact{
  display: flex;
  flex-direction: column;
  width: 10%;
  margin: 3% auto;
  background-color: #dee1ec;
  padding: 5%;
  border: 2px solid black;
}

#contact input, textarea{
  margin: 5%;
  border: 1px solid black;
}

#hidden {
  display: none;
}

ul{
  display: flex;
  flex-direction: column;
}

.reading {
  width: 30%;
}